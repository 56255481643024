// @ts-nocheck
import { color } from '@chakra-ui/react';
import React from 'react';

import type { TransactionType } from 'types/api/transaction';

import Tag from 'ui/shared/chakra/Tag';

export interface Props {
  types: Array<TransactionType>;
  isLoading?: boolean;
}

const TYPES_ORDER: Array<TransactionType> = [
  'blob_transaction',
  'rootstock_remasc',
  'rootstock_bridge',
  'token_creation',
  'contract_creation',
  'token_transfer',
  'contract_call',
  'coin_transfer',
];

const TxType = ({ types, isLoading }: Props) => {
  const typeToShow = types.sort((t1, t2) => TYPES_ORDER.indexOf(t1) - TYPES_ORDER.indexOf(t2))[0];

  let label;
  let colorScheme;

  switch (typeToShow) {
    case 'contract_call':
      label = 'Contract call';
      colorScheme = { bg: '#1A5363', color: '#00BBDB' };
      break;
    case 'blob_transaction':
      label = 'Blob txn';
      colorScheme = 'yellow';
      break;
    case 'contract_creation':
      label = 'Contract creation';
      colorScheme = 'blue';
      break;
    case 'token_transfer':
      label = 'Token transfer';
      colorScheme = { bg: '#65632E', color: '#DBD200' };
      break;
    case 'token_creation':
      label = 'Token creation';
      colorScheme = 'orange';
      break;
    default:
      label = 'Transaction';
      colorScheme = { bg: '#4E1B63', color: '#CC00FF' };
  }

  if (typeof colorScheme === 'string') {
    return (
      <Tag colorScheme={ colorScheme } isLoading={ isLoading }>
        { label.toUpperCase() }
      </Tag>
    );
  } else {
    return (
      <Tag bg={ colorScheme.bg } color={ colorScheme.color } isLoading={ isLoading }>
        { label.toUpperCase() }
      </Tag>
    );
  }
};

export default TxType;
